<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-form-group
          label="Статус ідентифікації"
          label-cols="3"
          :horizontal="true"
          label-for="identificationStatus"
        >
          <b-form-select
            id="identificationStatus"
            v-model="identification_status_id"
            :options="statuses"
            value-field="id"
            text-field="name"
          />
        </b-form-group>

        <b-form-group
          label="Дата ідентификації"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input type="date" v-model="identification_date" />
        </b-form-group>

        <b-form-group
          label="Дата встановлення ділових відносин"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input
            type="date"
            v-model="establishing_relations_date"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Дата проведення верифікації (за допомогою ІСЕІ)"
          label-cols="3"
          :horizontal="true"
        >
          <template v-if="form.is_isei_verified" v-slot:label>
            Дата проведення верифікації (за допомогою ІСЕІ)
            <b-icon-info-circle v-b-modal.isei-verification />
          </template>
          <b-form-input
            disabled
            type="date"
            v-model="isei_verification_date"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Дата проведення верифікації"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input
            type="date"
            v-model="video_verification_date"
          ></b-form-input>
        </b-form-group>

        <ValidationProvider
          name="спосіб проведення верифікації"
          v-slot="{ errors, dirty }"
          vid="verification_method_id"
        >
          <b-form-group
            label="Спосіб проведення верифікації"
            label-cols="3"
            :horizontal="true"
            label-for="verificationMethod"
          >
            <b-form-select
              id="verificationMethod"
              v-model="verification_method_id"
              :options="verificationMethods"
              value-field="id"
              text-field="name"
              :state="setValidationState(errors, dirty)"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <b-form-group
          label="Дата відмови від установлення /підтримання ділових (договірних) відносин"
          label-cols="3"
          horizontal
        >
          <b-form-group>
            <b-form-input
              type="date"
              id="stoppingBusinessRelationsDate"
              v-model="stop_relations_date"
            />
          </b-form-group>

          <ValidationProvider
            name="коментар"
            :rules="{ regex: /^[\d\sА-Яа-яёЁЇїІіЄєҐґэЭ\-,.:'ʼ;!?]{0,100}$/ }"
            v-slot="{ errors }"
          >
            <b-form-group>
              <b-form-input
                type="text"
                placeholder="Коментар"
                v-model="stop_relations_comment"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-form-group>

        <b-form-group
          label="Дата замороження активів"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input type="date" v-model="asset_freeze_date" />
        </b-form-group>

        <b-form-group
          label="Дата розмороження активів"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input type="date" v-model="asset_unfreeze_date" />
        </b-form-group>

        <b-form-group
          label="Дата уточнення інформації"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input type="date" v-model="clarification_date" />
        </b-form-group>

        <b-form-group
          label="Дата завершення ділових відносин"
          label-cols="3"
          horizontal
        >
          <b-form-input type="date" v-model="ending_relations_date" />
        </b-form-group>

        <b-form-group
          label="Дата створення анкети"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input type="text" disabled v-model="created_at" />
        </b-form-group>

        <b-form-group
          label="Дата внесення останніх змін"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-input type="text" disabled v-model="updated_at" />
        </b-form-group>

        <ValidationProvider
          name="особа, відповідальна за здійснення ідентифікації та верифікації клієнта"
          :rules="{ regex: /^[\d\sА-Яа-яёЁЇїІіЄєҐґэЭ\-,.'ʼ:;!?]{0,255}$/ }"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Особа, відповідальна за здійснення ідентифікації та верифікації клієнта"
            label-cols="3"
            :horizontal="true"
          >
            <b-form-input
              type="text"
              v-model="responsible_person"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <b-form-group
          label="Задача на уточнення"
          label-cols="3"
          :horizontal="true"
        >
          <b-form-textarea v-model="clarification_task" />
        </b-form-group>

        <b-form-group label="Дата постановки" label-cols="3" :horizontal="true">
          <b-form-input type="date" v-model="staging_date" />
        </b-form-group>

        <b-form-group label="Дата виконання" label-cols="3" :horizontal="true">
          <b-form-input type="date" v-model="completion_date" />
        </b-form-group>

        <b-button
          v-if="$auth.can('create counterpart')"
          :disabled="submitting"
          type="submit"
          size="sm"
          variant="primary"
        >
          <i class="fa fa-dot-circle-o"></i> Зберегти
        </b-button>
      </b-form>
    </ValidationObserver>

    <b-modal
      v-if="form.is_isei_verified"
      id="isei-verification"
      hide-footer
      hide-header
      size="lg"
    >
      <p>
        <b>Тип аутентифікації, що було обрано користувачем:</b>
        {{ isei_verification.auth_type }}
      </p>
      <p>
        <b>Реквізити видавника сертифіката (Надавач):</b>
        {{ isei_verification.issuer }}
      </p>
      <p><b>Загальне ім’я Надавача:</b> {{ isei_verification.issuercn }}</p>
      <p>
        <b>Реєстраційний номер сертифіката у Надавача:</b>
        {{ isei_verification.serial }}
      </p>
      <p>
        <b>Реквізити власника сертифіката (користувача):</b>
        {{ isei_verification.subject }}
      </p>
      <p><b>Загальне ім’я користувача:</b> {{ isei_verification.subjectcn }}</p>
      <p>
        <b>Місто (населений пункт) користувача:</b>
        {{ isei_verification.locality }}
      </p>
      <p><b>Область (регіон) користувача:</b> {{ isei_verification.state }}</p>
      <p>
        <b>Найменування організації користувача:</b> {{ isei_verification.o }}
      </p>
      <p>
        <b>Назва підрозділу організації користувача:</b>
        {{ isei_verification.ou }}
      </p>
      <p><b>Посада користувача:</b> {{ isei_verification.title }}</p>
      <p><b>Ім’я користувача:</b> {{ isei_verification.givenname }}</p>
      <p><b>По батькові користувача:</b> {{ isei_verification.middlename }}</p>
      <p><b>Прізвище користувача:</b> {{ isei_verification.lastname }}</p>
      <p>
        <b>Адреса ел. пошти (e-mail) користувача:</b>
        {{ isei_verification.email }}
      </p>
      <p>{{ isei_verification.documents }}</p>
      <p><b>Дата народження:</b> {{ isei_verification.birthday }}</p>
      <p>{{ isei_verification.passport }}</p>
      <p>{{ isei_verification.addressJuridical }}</p>
      <p>
        <b>Адреса (фізична) користувача:</b> {{ isei_verification.address }}
      </p>
      <p><b>Телефон користувача:</b> {{ isei_verification.phone }}</p>
      <p><b>DNS-ім'я користувача:</b> {{ isei_verification.dns }}</p>
      <p>
        <b>Код за ЄДРПОУ користувача:</b> {{ isei_verification.edrpoucode }}
      </p>
      <p>
        <b
          >РНОКПП користувача або серія (за наявності) та номер паспорта (для
          користувачів, які через свої релігійні переконання відмовляються від
          прийняття реєстраційного номера облікової картки платника податків та
          офіційно повідомили про це відповідний контролюючий орган і мають
          відмітку у паспорті) (Додаток А, п. 8):</b
        >
        {{ isei_verification.drfocode }}
      </p>
      <p>{{ isei_verification.personIdentifier }}</p>
      <p>
        <b>Унікальний номер запису в Єдиному демографічному реєстрі:</b>
        {{ isei_verification.unzr }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import mixins from "@/mixins";

export default {
  name: "Polling",
  mixins: [mixins],
  data() {
    return {
      submitting: false,
    };
  },
  computed: {
    ...mapGetters({
      form: "counterpart/getProfileMaintain",
      statuses: "dictionary/allCounterpartIdentificationStatuses",
      verificationMethods: "dictionary/allCounterpartVerificationMethods",
    }),
    identification_status_id: {
      get() {
        return this.form.identification_status
          ? this.form.identification_status.id
          : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "identification_status_id",
          value,
        });
      },
    },
    identification_date: {
      get() {
        return this.form.identification_date
          ? this.form.identification_date
          : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "identification_date",
          value,
        });
      },
    },
    establishing_relations_date: {
      get() {
        return this.form.establishing_relations_date
          ? this.form.establishing_relations_date
          : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "establishing_relations_date",
          value,
        });
      },
    },
    isei_verification_date: {
      get() {
        return this.form.is_isei_verified
          ? moment(this.form.isei_verification.verified_at).format("YYYY-MM-DD")
          : "";
      },
    },
    isei_verification: {
      get() {
        return this.form.is_isei_verified ? this.form.isei_verification : null;
      },
    },
    video_verification_date: {
      get() {
        return this.form.video_verification_date
          ? this.form.video_verification_date
          : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "video_verification_date",
          value,
        });
      },
    },
    verification_method_id: {
      get() {
        return this.form.verification_method
          ? this.form.verification_method.id
          : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "verification_method_id",
          value,
        });
      },
    },
    stop_relations_date: {
      get() {
        return this.form.stop_relations_date
          ? this.form.stop_relations_date
          : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "stop_relations_date",
          value,
        });
      },
    },
    stop_relations_comment: {
      get() {
        return this.form.stop_relations_comment
          ? this.form.stop_relations_comment
          : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "stop_relations_comment",
          value,
        });
      },
    },
    asset_freeze_date: {
      get() {
        return this.form.asset_freeze_date ? this.form.asset_freeze_date : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "asset_freeze_date",
          value,
        });
      },
    },
    asset_unfreeze_date: {
      get() {
        return this.form.asset_unfreeze_date
          ? this.form.asset_unfreeze_date
          : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "asset_unfreeze_date",
          value,
        });
      },
    },
    clarification_date: {
      get() {
        return this.form.clarification_date ? this.form.clarification_date : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "clarification_date",
          value,
        });
      },
    },
    ending_relations_date: {
      get() {
        return this.form.ending_relations_date
          ? this.form.ending_relations_date
          : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "ending_relations_date",
          value,
        });
      },
    },
    staging_date: {
      get() {
        return this.form.staging_date ? this.form.staging_date : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "staging_date",
          value,
        });
      },
    },
    completion_date: {
      get() {
        return this.form.completion_date ? this.form.completion_date : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "completion_date",
          value,
        });
      },
    },
    responsible_person: {
      get() {
        return this.form.responsible_person ? this.form.responsible_person : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "responsible_person",
          value,
        });
      },
    },
    clarification_task: {
      get() {
        return this.form.clarification_task ? this.form.clarification_task : "";
      },
      set(value) {
        this.$store.commit("counterpart/updateProfileMaintainField", {
          key: "clarification_task",
          value,
        });
      },
    },
    created_at: function () {
      return this.$store.state.counterpart.counterpart.created_at;
    },
    updated_at: function () {
      return this.$store.state.counterpart.counterpart.updated_at;
    },
    submissionForm: function () {
      let form = this.form;
      form.id = this.$route.params.id;
      return form;
    },
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      this.$store
        .dispatch("counterpart/updateProfileMaintain", this.submissionForm)
        .then(() => (this.submitting = false))
        .catch(() => (this.submitting = false));
    },
  },
};
</script>
